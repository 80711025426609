import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 入库单异常记录列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14328
 */
export function recordPage(data, params) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/ex/record/page', data, {
    params,
  });
}
/**
 * @description: 入库单异常记录列表导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20019
 */
export function receiptOrderExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/receipt_order/export', data, { fileName: '入库挂单', extension: 'xlsx' });
}
