import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 移库单异常记录列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19807
 */
export function recordPage(data, params) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_ex_record/page', data, {
    params,
  });
}
/**
 * @description: 移库单异常记录列表导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19809
 */
export function transferExRecordExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/transfer_ex_record/export', data, { fileName: '移库挂单', extension: 'xlsx' });
}
