import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import {
  EX_TYPE,
  EX_TYPE_ENUM,
  HANDLE_STATUS,
  HANDLE_STATUS_ENUM,
  DELIVER_TYPEENUM,
} from './constant';

export const TABLECOLUMN = [{
  label: '出库单号',
  prop: 'deliverOrderNo',
  width: 180,
  url: '/in-warehouse/inbound-order/detail',
}, {
  label: '挂单类型',
  prop: 'exType',
  width: 120,
  formatter: ({ exType }) => EX_TYPE_ENUM[exType],
}, {
  label: '处理状态',
  prop: 'handleStatus',
  width: 120,
  formatter: ({ handleStatus }) => HANDLE_STATUS_ENUM[handleStatus],
}, {
  label: '外部单号',
  prop: 'externalOrderNo',
  width: 120,
}, {
  label: '货主名称',
  prop: 'shipperName',
  width: 120,
}, {
  label: '出库单类型',
  prop: 'deliverType',
  width: 120,
  formatter: ({ deliverType }) => DELIVER_TYPEENUM[deliverType],
}, {
  label: '预约总数量',
  prop: 'bookingNum',
  width: 140,
}, {
  label: '已拣货数量',
  prop: 'pickNum',
  width: 140,
}, {
  label: '已发运数量',
  prop: 'shipNum',
  width: 140,
},
{
  label: '创建人',
  prop: 'createUserName',
  width: 140,
},
{
  label: '创建时间',
  prop: 'createTime',
  ...DateTime,
}, {
  label: '任务下发时间',
  prop: 'taskCreateTime',
  ...DateTime,
}, {
  label: '最后发运时间',
  prop: 'lastShipTime',
  ...DateTime,
}];

export function serchFields() {
  return [
    {
      label: '出库单号',
      prop: 'deliverOrderNo',
      component: FormType.INPUT,
    }, {
      label: '异常类型',
      prop: 'exType',
      component: FormType.SELECT,
      options: EX_TYPE,
    }, {
      label: '处理状态',
      prop: 'handleStatus',
      component: FormType.SELECT,
      options: HANDLE_STATUS,
    }, {
      label: '创建时间',
      prop: 'createTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    }, {
      label: '任务下发时间',
      prop: 'taskCreateTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    }, {
      label: '最后发运时间',
      prop: 'lastShipTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    },
  ];
}
