<template>
  <nh-drawer
    v-model="drawerVisible"
    v-loading="loading.loadDeliverOrderDetail"
    :before-close="handleClose"
    destroy-on-close
    title="出库单详情"
    size="1111px"
    :track="{
      name_zh: `出库管理/${nameZh}/抽屉-详情`,
      trackName: $route.path
    }"
  >
    <nh-dialog-form
      class="owner-detail"
      label-position="right"
    >
      <nh-dialog-form-item
        v-for="(item, index) in DetailFromInfo(StoreData)"
        :key="index"
        :span="8"
        :label="item.label"
      >
        <span v-if="item.timeFormat">
          {{ item.timeFormat(StoreData[item.prop]) }}
        </span>
        <span v-else-if="item.ENUM">{{ item.ENUM[StoreData[item.prop]] }}</span>
        <span v-else>{{ StoreData[item.prop] }}</span>
      </nh-dialog-form-item>
      <nh-dialog-form-item
        :span="24"
        label="附件："
      >
        <el-image
          v-for="(item) in files.images"
          :key="item.id"
          style="width: 100px; height: 100px;"
          class="preview-image"
          :src="item.filePath"
          :preview-src-list="previewImageSrcList"
          :initial-index="1"
          fit="cover"
        />
        <div v-if="files.otherFiles.length > 0" class="file-show-box">
          <div
            v-for="item in files.otherFiles"
            :key="item.id"
            class="file-rows"
          >
            <div class="file-info">
              <div class="file-name">
                {{ getFileName(item.filePath) }}
              </div>
            </div>
            <div class="file-opera">
              <nh-button
                type="text"
                @click="handleDownloadFile(item)"
              >
                下载
              </nh-button>
            </div>
          </div>
        </div>
      </nh-dialog-form-item>
    </nh-dialog-form>
    <div class="block">
      <el-tabs>
        <el-tab-pane label="出库单明细">
          <DetailTable :goods="StoreData.goods" />
        </el-tab-pane>
        <el-tab-pane label="关联的任务">
          <TaskTable :tasks="StoreData.subTaskResps" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <template #footer>
      <div class="footer">
        <nh-button
          type="primary"
          @click="handleClose"
        >
          关闭
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import DetailTable from '@/views/out-warehouse/deliver-order/detailTable/detailTable.vue';
import TaskTable from '@/views/out-warehouse/deliver-order/detail/subpages/TaskTable/index.vue';
import { deliverOrderDetail } from '@/views/out-warehouse/deliver-order/api';
import DetailFromInfo from '@/views/out-warehouse/deliver-order/detail/detailForm';
import mixin from '@/views/out-warehouse/deliver-order/mixin';
import { getFileNameByFileUrl, downloadFile } from '@/views/out-warehouse/deliver-order/utils';

export default {
  name: 'DeliverOrderDetail',
  components: {
    DetailTable,
    TaskTable,
  },
  filters: {
    filterLabel(val, arr) {
      const filertArr = arr && arr.find((item) => item.value === val);
      return filertArr && filertArr.label;
    },
  },
  mixins: [mixin, loadingMixin],
  inject: ['nameZh'],
  props: {
    idData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['handleClickStore'],
  data() {
    return {
      drawerVisible: false,
      DetailFromInfo,
      loading: {
        loadDeliverOrderDetail: false,
      },
      StoreData: {
      },
      receiving: [{
        temp: '',
        time: '10:00-18:00',
      }],
      delivery: [{
        temp: '',
        time: '10:00-18:00',
      }],
    };
  },
  computed: {
    files() {
      const images = [];
      const otherFiles = [];

      const IMAGE_SUFFIXS = ['jfif', 'png', 'bmp', 'jpg', 'gif'];

      this.StoreData?.fileAttachments?.forEach((element) => {
        const isImage = IMAGE_SUFFIXS.some((suffix) => element.filePath.endsWith(suffix));

        if (isImage) {
          images.push(element);
        } else {
          otherFiles.push(element);
        }
      });

      return {
        images,
        otherFiles,
      };
    },
    previewImageSrcList() {
      return this.files.images.map(({ filePath }) => filePath);
    },
  },
  watch: {
    idData: {
      deep: true,
      handler(val) {
        if (val.num) this.init(val.id);
      },
    },
  },
  methods: {
    init(id) {
      this.loadDeliverOrderDetail(id);
      this.drawerVisible = true;
    },
    async loadDeliverOrderDetail(id) {
      this.StoreData = await deliverOrderDetail({ id });
    },
    handleClose() {
      this.$emit('handleClickStore', true);
      this.drawerVisible = false;
    },
    getFileName(value) {
      return this.fileNameRemoveTime(getFileNameByFileUrl(value, true));
    },
    fileNameRemoveTime(fileName) {
      const fileNames = `${fileName}` ? fileName.split('_') : [];
      // 上传时是使用下划线_ 区分时间戳和文件名的，展示时 去掉时间戳
      return fileNames.filter((item, index) => index > 0).join('_');
    },
    handleDownloadFile(fileItem) {
      downloadFile('/warehouse_management_system/file/download', { path: fileItem.filePath });
    },
  },
};
</script>
<style lang="scss" scoped>
.file-show-box {
  padding: 12px 24px;
  background-color: #f6f8fa;
  border-radius: 4px;
  width: 86%;

  .file-rows {
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .file-info {
      & > div {
        height: 24px;
      }

      .file-name {
        color: #606266;
        font-size: 14px;
      }

      .file-upload-time {
        font-size: 14px;
        color: #909399;
      }
    }

    .file-opera {
      .text-color {
        color: #f56c6c;
      }
    }
  }
}

.block {
  border-top: 1px solid #ebedf0;
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}

</style>
<style lang="scss">
.owner-detail {
  .el-form-item__label {
    color: #606266;
  }

  .el-form-item__content {
    color: #171717;
  }
}

.el-tabs__nav-wrap::after {
  display: none;
}

.el-tabs__item.is-active {
  color: #323233;
}

.el-tabs__active-bar {
  height: 2px;
  background: #06b880;
}

.preview-image {
  margin-right: 10px;
}

</style>
