import { formatKeyValueObject, formatKeyValueArray } from '@/utils/base';
import { StoreBillTypeText } from '@/constant/task';

export const EX_TYPE = [
  {
    value: 'ONE_CLICK',
    label: '一键移库',
  },
  {
    value: 'TALLY',
    label: '理货移库',
  },
  {
    value: 'DAILY',
    label: '日常补货',
  },
  {
    value: 'URGENT',
    label: '紧急补货',
  },
];
export const EX_TYPE_ENUM = formatKeyValueObject(EX_TYPE);

// NONE_HANDLE HANDLED
export const HANDLE_STATUS = [
  {
    label: '未处理',
    value: 'NONE_HANDLE',
  },
  {
    label: '已处理',
    value: 'HANDLED',
  },
];
export const HANDLE_STATUS_ENUM = formatKeyValueObject(HANDLE_STATUS);

export const RECEIPT_TYPE = formatKeyValueArray(StoreBillTypeText);
