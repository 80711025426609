import { formatKeyValueObject, formatKeyValueArray } from '@/utils/base';
import { StoreBillTypeText } from '@/constant/task';

export const EX_TYPE = [
  {
    label: '拣货超时',
    value: 'PICK_TIME_OUT',
  },
  {
    label: '发运超时',
    value: 'SHIP_TIME_OUT',
  },
];
export const EX_TYPE_ENUM = formatKeyValueObject(EX_TYPE);

// NONE_HANDLE HANDLED
export const HANDLE_STATUS = [
  {
    label: '未处理',
    value: 'NONE_HANDLE',
  },
  {
    label: '已处理',
    value: 'HANDLED',
  },
];

export const DELIVER_TYPE = [
  {
    label: '订单出库',
    value: 'ORDER_OUT',
  }, {
    label: '调拨出库',
    value: 'ALLOT_OUT',
  }, {
    label: '移库移位(出)',
    value: 'SHIFT_PARKING_OUT',
  }, {
    label: '调整出库',
    value: 'ADJUST_OUT',
  }, {
    label: '盘亏出库',
    value: 'INVENTORY_LESS',
  }, {
    label: '销毁出库',
    value: 'DESTROY_OUT',
  }, {
    label: '退供出库',
    value: 'CANCEL_OUT',
  }, {
    label: '总拣下架',
    value: 'TOTAL_PICK_DOWN',
  }, {
    label: '零担出库',
    value: 'TEMPORARY_OUT',
  }, {
    label: '其它出库',
    value: 'OTHER_IT_OUT',
  }, {
    label: '其他出库',
    value: 'OTHER_HE_OUT',
  }, {
    label: '调拨出库(库内)',
    value: 'ALLOT_OUT_BOUND_IN',
  },
];

export const HANDLE_STATUS_ENUM = formatKeyValueObject(HANDLE_STATUS);

export const RECEIPT_TYPE = formatKeyValueArray(StoreBillTypeText);

export const DELIVER_TYPEENUM = formatKeyValueObject(DELIVER_TYPE);
