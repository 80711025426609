<template>
  <div>
    <pl-block>
      <PlForm
        v-model="searchForm"
        mode="search"
        :fields="serchFields()"
        @submit="handleSearch"
        @reset="handleSearch"
      />
    </pl-block>
    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        v-loading="loading.getReservoirList"
        :data="reservoirList"
        :columns="TABLECOLUMN"
        :show-table-setting="true"
        @update:pagination="getReservoirListData"
      >
        <template #tableHeaderRight>
          <nh-button
            :track="{
              trackName: '报表中心/挂单明细/入库挂单tab页/[导出]'
            }"
            @click="handleExport"
          >
            导出
          </nh-button>
        </template>
        <template #receiptNo="{row}">
          <nh-link
            :to="{}"
            :text="row.receiptNo"
            @click="handleDetails(row)"
          />
        </template>
      </PlTable>
    </pl-block>
    <nh-drawer
      v-model="drawerVisible"
      :before-close="handleClose"
      destroy-on-close
      title="订单基本信息"
      size="1111px"
      :track="{
        name_zh: '异常中心/入库订单/抽屉-详情',
        trackName: $route.path
      }"
    >
      <InWarehouseDrawer v-if="drawerVisible" :receipt-order-no="receiptOrderNo" />
    </nh-drawer>
  </div>
</template>
<script setup>
import {
  ref,
} from 'vue';
import moment from '@/utils/moment';
import InWarehouseDrawer from '@/views/in-warehouse/inbound-order/detail/index.vue';
import { TABLECOLUMN, serchFields } from './fileds';
import { recordPage, receiptOrderExport } from './api';
import useSearch from '../hooks/useSearch';

const receiptOrderNo = ref(null);
const drawerVisible = ref(false);

const getTime = (beginTime, endTime, arr) => {
  if (Array.isArray(arr)) {
    return {
      [beginTime]: moment.startOf(moment.toTimestame(arr[0])),
      [endTime]: moment.ensOf(moment.toTimestame(arr[1])),
    };
  }
  return {};
};

const getSearchParams = (searchForm) => {
  const searchDATA = {
    ...searchForm,
    receiptCreateTime: getTime('leftDateTime', 'rightDateTime', searchForm.receiptCreateTimeArr),
    registerTime: getTime('leftDateTime', 'rightDateTime', searchForm.registerTimeArr),
    acceptanceTime: getTime('leftDateTime', 'rightDateTime', searchForm.acceptanceTimeArr),
    acceptanceCommitTime: getTime('leftDateTime', 'rightDateTime', searchForm.acceptanceCommitTimeArr),
    upperEndTime: getTime('leftDateTime', 'rightDateTime', searchForm.upperEndTimeArr),
    defaultExTypeSet: ['ACCEPTANCE_TIME_OUT', 'UPPER_TIME_OUT'],
  };
  return {
    searchDATA,
  };
};

const {
  reservoirList, loading, pagination, getReservoirListData, handleSearch, handleExport, searchForm,
} = useSearch({ getSearchParams, recordPage, exportSearchResult: receiptOrderExport });

const handleDetails = ({ receiptNo }) => {
  receiptOrderNo.value = receiptNo;
  drawerVisible.value = true;
};
const handleClose = () => {
  drawerVisible.value = false;
  getReservoirListData();
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
