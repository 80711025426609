import { formatKeyValueObject, formatKeyValueArray } from '@/utils/base';
import { StoreBillTypeText } from '@/constant/task';

export const EX_TYPE = [
  {
    value: 'SAMPLING_COUNT',
    label: '抽盘',
  },
  {
    value: 'DYNAMIC_COUNT',
    label: '动碰盘点',
  },
  {
    value: 'RE_COUNT',
    label: '复盘',
  },
  {
    value: 'RANDOM_COUNT',
    label: '随机盘点',
  },
];
export const EX_TYPE_ENUM = formatKeyValueObject(EX_TYPE);

// NONE_HANDLE HANDLED
export const HANDLE_STATUS = [
  {
    label: '未处理',
    value: 'NONE_HANDLE',
  },
  {
    label: '已处理',
    value: 'HANDLED',
  },
];
export const HANDLE_STATUS_ENUM = formatKeyValueObject(HANDLE_STATUS);

export const RECEIPT_TYPE = formatKeyValueArray(StoreBillTypeText);
