<template>
  <div class="list-container-component page">
    <nh-container class="flex_column buttom-box">
      <div class="flex_column full-content">
        <el-tabs
          v-model="activeName"
          class="flex_column buttom-box"
          @tab-click="handleTabChange"
        >
          <el-tab-pane
            :label="PUT_STORGE.value"
            :name="PUT_STORGE.value"
            class="buttom-box"
          >
            <template #label>
              <span>
                <span class="span-box">
                  <span>{{ PUT_STORGE.label }}</span>
                  <span class="active">({{ unHandledNums[PUT_STORGE.value] }})</span>
                  <!-- <el-badge
                    :value="3"
                    :max="99"
                    class="item"
                  /></span> -->
                </span>
              </span>
            </template>
            <putStorge v-if="activeName === PUT_STORGE.value" />
          </el-tab-pane>
          <el-tab-pane
            :label="OUT_STORGE.value"
            :name="OUT_STORGE.value"
            class="buttom-box"
          >
            <template #label>
              <span>
                <span class="span-box">
                  <span>{{ OUT_STORGE.label }}</span>
                  <span class="active">({{ unHandledNums[OUT_STORGE.value] }})</span>
                  <!-- <el-badge
                    :value="200"
                    :max="99"
                    class="item"
                  /></span> -->
                </span>
              </span>
            </template>
            <outStorge v-if="activeName === OUT_STORGE.value" />
          </el-tab-pane>
          <el-tab-pane
            :label="inventory.value"
            :name="inventory.value"
            class="buttom-box"
          >
            <template #label>
              <span>
                <span class="span-box">
                  <span>{{ inventory.label }}</span>
                  <span class="active">({{ unHandledNums[inventory.value] }})</span>
                  <!-- <el-badge
                    :value="200"
                    :max="99"
                    class="item"
                  /></span> -->
                </span>
              </span>
            </template>
            <inventoryDetail v-if="activeName === inventory.value" />
          </el-tab-pane>
          <el-tab-pane
            :label="MoveLibrary.value"
            :name="MoveLibrary.value"
            class="buttom-box"
          >
            <template #label>
              <span>
                <span class="span-box">
                  <span>{{ MoveLibrary.label }}</span>
                  <span class="active">({{ unHandledNums[MoveLibrary.value] }})</span>
                  <!-- <el-badge
                    :value="200"
                    :max="99"
                    class="item"
                  /></span> -->
                </span>
              </span>
            </template>
            <moveLibrary v-if="activeName === MoveLibrary.value" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </nh-container>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  onBeforeUnmount,
} from 'vue';
import { useRoute } from 'vue-router';
import outStorge from './components/outStorge/index.vue';
import putStorge from './components/putStorge/index.vue';
import inventoryDetail from './components/inventory/index.vue';
import moveLibrary from './components/moveLibrary/index.vue';
import {
  PUT_STORGE, OUT_STORGE, inventory, MoveLibrary, TABS,
} from './fileds';
import {
  receiptOrderUnHandledCount,
  deliverOrderExRecordUnHandledCount,
  countOrderExRecordUnHandledCount,
  transferExRecordUnHandledCount,
} from './api';

const activeName = ref(PUT_STORGE.value);
const unHandledNums = reactive({});

const name = ref(PUT_STORGE.label);
const startTime = ref(Date.now());
const route = useRoute();
const handelQuery = () => {
  window.YlTrack.report({
    trackType: 'view',
    duration_time: (Date.now() - startTime.value) / 1000,
    trackName: route.path,
    trackNameZh: `报表中心/挂单明细/${name.value}tab页`,
  });
};

const handleGetUnHandledNums = {
  PUT_STORGE: receiptOrderUnHandledCount,
  OUT_STORGE: deliverOrderExRecordUnHandledCount,
  inventory: countOrderExRecordUnHandledCount,
  MoveLibrary: transferExRecordUnHandledCount,
};

const getUnHandleNums = async (type) => {
  if (handleGetUnHandledNums[type]) {
    const result = await handleGetUnHandledNums[type]();
    unHandledNums[type] = result || 0;
  }
};

Object.keys(TABS).forEach((key) => {
  unHandledNums[key] = 0;
  getUnHandleNums(key);
});

const handleTabChange = () => {
  getUnHandleNums(activeName.value);
  handelQuery();
  switch (activeName.value) {
    case PUT_STORGE.value:
      name.value = PUT_STORGE.label;
      break;
    case OUT_STORGE.value:
      name.value = OUT_STORGE.label;
      break;
    case inventory.value:
      name.value = inventory.label;
      break;
    case MoveLibrary.value:
      name.value = MoveLibrary.label;
      break;
    default:
      name.value = null;
  }
};

onBeforeUnmount(() => {
  handelQuery();
});
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.page {
  :deep(.el-tabs__content) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.active {
  color: red;
}

.list-container-component {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.buttom-box {
  flex: 1;

  :deep(.buttom-box) {
    flex: 1;
  }
}

.base-label {
  color: #606266;
}

.base-value {
  font-weight: 600;
  color: #171717;
}

.base {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;

  & > div {
    width: 20%;
    min-width: 140px;
    padding: 8px 16px;
  }
}

.create_base {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;

  & > div {
    box-sizing: border-box;
    width: 50%;
    padding: 8px 16px;
  }
}
</style>
