import { formatKeyValueObject, formatKeyValueArray } from '@/utils/base';
import { StoreBillTypeText } from '@/constant/task';

export const EX_TYPE = [
  {
    label: '验收超时',
    value: 'ACCEPTANCE_TIME_OUT',
  },
  {
    label: '上架超时',
    value: 'UPPER_TIME_OUT',
  },
  // {
  //   label: '验货超收',
  //   value: 'ACCEPTANCE_OVERCHARGE',
  // },
  // {
  //   label: '验货缺收',
  //   value: 'ACCEPTANCE_OUT_OF_STOCK',
  // },
];
export const EX_TYPE_ENUM = formatKeyValueObject(EX_TYPE);

// NONE_HANDLE HANDLED
export const HANDLE_STATUS = [
  {
    label: '未处理',
    value: 'NONE_HANDLE',
  },
  {
    label: '已处理',
    value: 'HANDLED',
  },
];
export const HANDLE_STATUS_ENUM = formatKeyValueObject(HANDLE_STATUS);

export const RECEIPT_TYPE = formatKeyValueArray(StoreBillTypeText);
