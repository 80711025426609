import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 盘点超时异常记录列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20021
 */
export function recordPage(data, params) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order_ex_record/page', data, {
    params,
  });
}
/**
 * @description: 盘点超时异常记录列表导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20023
 */
export function countOrderExRecordExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/count_order_ex_record/export', data, { fileName: '盘点挂单', extension: 'xlsx' });
}
