import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import { StoreBillTypeText } from '@/constant/task';
import {
  EX_TYPE,
  EX_TYPE_ENUM,
  HANDLE_STATUS,
  HANDLE_STATUS_ENUM,
} from './constant';

export const TABLECOLUMN = [{
  label: '入库单号',
  prop: 'receiptNo',
  width: 180,
  url: '/in-warehouse/inbound-order/detail',
}, {
  label: '挂单类型',
  prop: 'exType',
  width: 180,
  formatter: ({ exType }) => EX_TYPE_ENUM[exType],
}, {
  label: '处理状态',
  prop: 'handleStatus',
  width: 120,
  formatter: ({ handleStatus }) => HANDLE_STATUS_ENUM[handleStatus],
}, {
  label: '外部单号',
  prop: 'outNo',
  width: 120,
}, {
  label: '货主名称',
  prop: 'shipperName',
  width: 120,
}, {
  label: '入库单类型',
  prop: 'receiptType',
  width: 120,
  formatter: ({ receiptType }) => StoreBillTypeText[receiptType],
}, {
  label: '车牌号',
  prop: 'plateNumber',
  width: 120,
}, {
  label: '预约总数量',
  prop: 'preNumber',
  width: 140,
}, {
  label: '预约板数',
  prop: 'bookingPalletNum',
  width: 140,
}, {
  label: '已验收数量',
  prop: 'postNumber',
  width: 140,
}, {
  label: '已验收板数',
  prop: 'receiptPalletNum',
  width: 140,
}, {
  label: '已上架数量',
  prop: 'putawayAmount',
  width: 140,
},

{
  label: '已上架板数',
  prop: 'putawayPalletNum',
  width: 140,
}, {
  label: '创建人',
  prop: 'receiptCreateUserName',
  width: 140,
},
{
  label: '创建时间',
  prop: 'receiptCreateTime',
  ...DateTime,
}, {
  label: '到货登记时间',
  prop: 'registerTime',
  ...DateTime,
}, {
  label: '最后一次验收时间',
  prop: 'lastAcceptanceTime',
  ...DateTime,
}];

export function serchFields() {
  return [
    {
      label: '入库单号',
      prop: 'receiptNo',
      component: FormType.INPUT,
    }, {
      label: '异常类型',
      prop: 'exType',
      component: FormType.SELECT,
      options: EX_TYPE,
    }, {
      label: '处理状态',
      prop: 'handleStatus',
      component: FormType.SELECT,
      options: HANDLE_STATUS,
    }, {
      label: '创建时间',
      prop: 'receiptCreateTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    }, {
      label: '到货登记时间',
      prop: 'registerTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    }, {
      label: '开始验收时间',
      prop: 'acceptanceTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    }, {
      label: '验收确认时间',
      prop: 'acceptanceCommitTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    },
    {
      label: '完成上架时间',
      prop: 'upperEndTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    },
  ];
}
