import {
  ref, unref,
} from 'vue';

const useSearch = ({
  getSearchParams,
  recordPage,
  exportSearchResult,
}) => {
  const reservoirList = ref([]); // 查询列表
  const loading = ref({ getReservoirList: false }); // loading状态
  const pagination = ref({ page: 1, size: 10, total: 0 }); // 分页数据
  const searchForm = ref({ handleStatus: 'NONE_HANDLE' }); // 查询条件

  const getReservoirListData = async () => {
    loading.value.getReservoirList = true;
    const { searchDATA } = getSearchParams(unref(searchForm));
    const result = await recordPage(
      searchDATA,
      { page: pagination.value.page, size: pagination.value.size },
    ).catch(() => ({}));
    loading.value.getReservoirList = false;
    reservoirList.value = result.records || [];
    pagination.value.total = result.total || 0;
  };

  const handleSearch = () => {
    pagination.value.page = 1;
    getReservoirListData();
  };

  const handleExport = () => {
    const { searchDATA } = getSearchParams(unref(searchForm));
    exportSearchResult(searchDATA);
  };

  getReservoirListData();

  return {
    reservoirList,
    loading,
    pagination,
    getReservoirListData,
    handleSearch,
    handleExport,
    searchForm,
  };
};

export default useSearch;
