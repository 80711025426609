<template>
  <div>
    <pl-block>
      <PlForm
        v-model="searchForm"
        mode="search"
        :fields="serchFields()"
        @submit="handleSearch"
        @reset="handleSearch"
      />
    </pl-block>
    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        v-loading="loading.getReservoirList"
        :data="reservoirList"
        :columns="TABLECOLUMN"
        :show-table-setting="true"
        @update:pagination="getReservoirListData"
      >
        <template #tableHeaderRight>
          <nh-button
            :track="{
              trackName: '报表中心/挂单明细/出库挂单tab页/[导出]'
            }"
            @click="handleExport"
          >
            导出
          </nh-button>
        </template>
        <template #deliverOrderNo="{row}">
          <nh-link
            :to="{}"
            :text="row.deliverOrderNo"
            @click="handleDetails(row)"
          />
        </template>
      </PlTable>
    </pl-block>
    <outDetail :id-data="Id" @handleClickStore="getClosed" />
  </div>
</template>
<script setup>
import {
  ref,
} from 'vue';
// import loadingMixin from '@thales/loading';
// import ExceptionCenter from '@/constant/pageNames/exceptionCenter';
// import { YMDHMS } from '@/constant/timeFormat';
import moment from '@/utils/moment';

import outDetail from './detail.vue';
import { TABLECOLUMN, serchFields } from './fileds';
import { recordPage, deliverOrderExRecordExport } from './api';
import useSearch from '../hooks/useSearch';

const Id = ref({ id: '', num: 0 });
const getTime = (beginTime, endTime, arr) => {
  if (Array.isArray(arr)) {
    return {
      [beginTime]: moment.startOf(moment.toTimestame(arr[0])),
      [endTime]: moment.ensOf(moment.toTimestame(arr[1])),
    };
  }
  return {};
};
const getSearchParams = (searchForm) => {
  const searchDATA = {
    ...searchForm,
    ...getTime('startCreateTime', 'endCreateTime', searchForm.createTimeArr),
    ...getTime('startTaskCreateTime', 'endTaskCreateTime', searchForm.taskCreateTimeArr),
    ...getTime('startLastShipTime', 'endLastShipTime', searchForm.lastShipTimeArr),
  };
  return {
    searchDATA,
  };
};

const {
  reservoirList, loading, pagination, getReservoirListData, handleSearch, handleExport, searchForm,
} = useSearch({ getSearchParams, recordPage, exportSearchResult: deliverOrderExRecordExport });

const handleDetails = (row) => {
  Id.value.id = row.deliverOrderId;
  Id.value.num += 1;
};

const getClosed = (val) => {
  if (val) getReservoirListData();
};

</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
