import { wmsPlusHttp } from '@/utils/http/index';
/**
 * @description: 入库单未处理数
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20025
 */
export function receiptOrderUnHandledCount(data = {}) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/un_handled/count', data);
}
/**
 * @description: 出库单未处理数
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20103
 */
export function deliverOrderExRecordUnHandledCount(data = {}) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order_ex_record/un_handled/count', data);
}
/**
 * @description: 盘点单未处理数
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20029
 */
export function countOrderExRecordUnHandledCount(data = {}) {
  return wmsPlusHttp.post('/warehouse_management_system/count_order_ex_record/un_handled/count', data);
}

/**
 * @description: 移库单未处理数
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20027
 */
export function transferExRecordUnHandledCount(data = {}) {
  return wmsPlusHttp.post('/warehouse_management_system/transfer_ex_record/un_handled/count', data);
}
