import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 出库单异常记录列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20085
 */
export function recordPage(data, params) {
  return wmsPlusHttp.post('/warehouse_management_system/deliver_order_ex_record/page', data, {
    params,
  });
}
/**
 * @description: 出库单异常记录列表导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20087
 */
export function deliverOrderExRecordExport(params) {
  return wmsPlusHttp.download('/warehouse_management_system/deliver_order_ex_record/export', params, { fileName: '出库挂单', extension: 'xlsx' });
}
