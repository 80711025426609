<template>
  <div>
    <pl-block>
      <PlForm
        v-model="searchForm"
        mode="search"
        :fields="serchFields()"
        @submit="handleSearch"
        @reset="handleSearch"
      />
    </pl-block>
    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        v-loading="loading.getReservoirList"
        :data="reservoirList"
        :columns="TABLECOLUMN"
        :show-table-setting="true"
        @update:pagination="getReservoirListData"
      >
        <template #tableHeaderRight>
          <nh-button
            :track="{
              trackName: '报表中心/挂单明细/移库挂单tab页/[导出]'
            }"
            @click="handleExport"
          >
            导出
          </nh-button>
        </template>
        <template #transferOrderNo="{row}">
          <nh-link
            :to="{}"
            :text="row.transferOrderNo"
            @click="handleDetails(row)"
          />
        </template>
      </PlTable>
    </pl-block>
    <InWarehouseDrawer :id-data="Id" @handleClickStore="getClosed" />
  </div>
</template>
<script setup>
import { ref } from 'vue';
import InWarehouseDrawer from '@/views/in-library-management/library-transfer-management/library-transfer-details/index.vue';
import { TABLECOLUMN, serchFields } from './fileds';
import { recordPage, transferExRecordExport } from './api';
import useSearch from '../hooks/useSearch';

const Id = ref({ row: {}, num: 0 });

const getSearchParams = (searchForm) => {
  const searchDATA = {
    ...searchForm,
    createTimeBegin: searchForm.createTimeArr?.[0],
    createTimeEnd: searchForm.createTimeArr?.[1],
    lastUpShelfTimeBegin: searchForm.lastUpShelfTimeArr?.[0],
    lastUpShelfTimeEnd: searchForm.lastUpShelfTimeArr?.[1],
    lastDownShelfTimeBegin: searchForm.lastDownShelfTimeArr?.[0],
    lastDownShelfTimeEnd: searchForm.lastDownShelfTimeArr?.[1],
  };
  return {
    searchDATA,
  };
};
const {
  reservoirList, loading, pagination, getReservoirListData, handleSearch, handleExport, searchForm,
} = useSearch({ getSearchParams, recordPage, exportSearchResult: transferExRecordExport });

const handleDetails = (row) => {
  Id.value.row = { ...row, id: row.transferOrderId };
  Id.value.num += 1;
};

const getClosed = (val) => {
  if (val) getReservoirListData();
};

</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
