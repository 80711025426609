import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import {
  EX_TYPE,
  EX_TYPE_ENUM,
  HANDLE_STATUS,
  HANDLE_STATUS_ENUM,
} from './constant';

export const TABLECOLUMN = [{
  label: '移库单号',
  prop: 'transferOrderNo',
  width: 180,
}, {
  label: '处理状态',
  prop: 'handleStatus',
  width: 120,
  formatter: ({ handleStatus }) => HANDLE_STATUS_ENUM[handleStatus],
}, {
  label: '移库类型',
  prop: 'transferType',
  width: 180,
  formatter: ({ transferType }) => EX_TYPE_ENUM[transferType],
}, {
  label: '移库库存量',
  prop: 'stockAmount',
  width: 120,
}, {
  label: '移库板数',
  prop: 'stockPalletNum',
  width: 120,
}, {
  label: '已下架量',
  prop: 'unloadAmount',
  width: 120,
}, {
  label: '已下架板数',
  prop: 'unloadPalletNum',
  width: 120,
}, {
  label: '已上架量',
  prop: 'onloadAmount',
  width: 140,
}, {
  label: '已上架板数',
  prop: 'onloadPalletNum',
  width: 140,
},
{
  label: '创建人',
  prop: 'createUserName',
  width: 140,
},
{
  label: '创建时间',
  prop: 'createTime',
  ...DateTime,
}, {
  label: '最后移库下架时间',
  prop: 'lastDownShelfTime',
  ...DateTime,
}, {
  label: '最后移库上架时间',
  prop: 'lastUpShelfTime',
  ...DateTime,
}];

export function serchFields() {
  return [
    {
      label: '移库单号',
      prop: 'transferOrderNo',
      component: FormType.INPUT,
    }, {
      label: '移库类型',
      prop: 'transferType',
      component: FormType.SELECT,
      options: EX_TYPE,
    }, {
      label: '处理状态',
      prop: 'handleStatus',
      component: FormType.SELECT,
      options: HANDLE_STATUS,
    }, {
      label: '创建时间',
      prop: 'createTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    }, {
      label: '最后移库下架时间',
      prop: 'lastDownShelfTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    }, {
      label: '最后移库上架时间',
      prop: 'lastUpShelfTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    },
  ];
}
