const PUT_STORGE = {
  label: '入库挂单',
  value: 'PUT_STORGE',
};
const OUT_STORGE = {
  label: '出库挂单',
  value: 'OUT_STORGE',
};
const inventory = {
  label: '盘点挂单',
  value: 'inventory',
};
const MoveLibrary = {
  label: '移库挂单',
  value: 'MoveLibrary',
};

const TABS = {
  PUT_STORGE, OUT_STORGE, inventory, MoveLibrary,
};
export {
  PUT_STORGE,
  OUT_STORGE,
  inventory,
  MoveLibrary,
  TABS,
};
