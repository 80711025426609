import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import {
  EX_TYPE,
  EX_TYPE_ENUM,
  HANDLE_STATUS,
  HANDLE_STATUS_ENUM,
} from './constant';

export const TABLECOLUMN = [{
  label: '盘点单号',
  prop: 'countOrderNo',
  width: 180,
  url: '/in-warehouse/inbound-order/detail',
}, {
  label: '处理状态',
  prop: 'handleStatus',
  width: 120,
  formatter: ({ handleStatus }) => HANDLE_STATUS_ENUM[handleStatus],
}, {
  label: '盘点类型',
  prop: 'countType',
  width: 120,
  formatter: ({ countType }) => EX_TYPE_ENUM[countType],
}, {
  label: '盘点描述',
  prop: 'description',
  width: 120,
}, {
  label: '总库位',
  prop: 'totalLocationNum',
  width: 120,
}, {
  label: '已盘库位',
  prop: 'countLocationNum',
  width: 120,
}, {
  label: '待盘库位',
  prop: 'unCountLocationNum',
  width: 140,
}, {
  label: '创建人',
  prop: 'createUserName',
  width: 140,
}, {
  label: '创建时间',
  prop: 'createTime',
  ...DateTime,
},
{
  label: '最后盘点时间',
  prop: 'lastCountTime',
  ...DateTime,
},
];

export function serchFields() {
  return [
    {
      label: '盘点单号',
      prop: 'countOrderNo',
      component: FormType.INPUT,
    }, {
      label: '盘点类型',
      prop: 'countType',
      component: FormType.SELECT,
      options: EX_TYPE,
    }, {
      label: '处理状态',
      prop: 'handleStatus',
      component: FormType.SELECT,
      options: HANDLE_STATUS,
    }, {
      label: '创建时间',
      prop: 'createTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    }, {
      label: '最后盘点时间',
      prop: 'lastCountTimeArr',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    },
  ];
}
